.project-membership {

    &-table-header {
        border-collapse: collapse;
        height: 100px;
        padding-top: 0% !important;
        margin-top: 2.023%;
    }

    &-table-header thead th {
        position: sticky;
        width: fit-content;
        top: 0;
        background-color: #f6f6f6;
        /* overflow: hidden; */
        padding-right: 10px;
        white-space: nowrap;

        @include dark-mode {
            background-color: #252731;
        }
    }

    &-table-header tbody th td {
        width: fit-content;
        overflow-y: scroll;
        left: 0;
    }
}

.label-font-color {
    @include dark-mode {
        color: #e7eef8;
    }
}

.input-box-shadow-unset {
    box-shadow: none !important;
    border: none !important;

}

.prms-table-header {
    text-align: center;
    z-index: 1;
}

.center-align-checkbox {
    float: unset !important;
}

.center-align-table-data {
    text-align: center;
    white-space: nowrap;
}

.blurred {
    filter: blur(1px);
    /* Adjust the blur value as needed */
}
.datepicker-container .react-datepicker-wrapper{
    width: 100% !important;
}
.datepicker-container .react-datepicker-wrapper{
    width: 100% !important;
}

  
  
.prms-page-wrapper {
    height: 100%
}

.height-100 {
    height: 100%;
}

.review-history-container-width {
    max-width: 100% !important;
}

.validation-message{
    color: #f35421;
    text-align: center;
    font-size: 0.875em;
}
.remove-icon.form-control.is-invalid{
    background-image: unset !important;
}
.remove-icon.form-select.is-invalid:not([multiple]):not([size]){
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e"), none;
    // background-image: unset !important;
}

.bold-text {
    font-weight: bold;
    color: #0d8eb9 !important;
    text-decoration: none;
    cursor: pointer; 
}

.mandatory-field{
    border-color: #f35421 !important;
    box-shadow: none !important
}
.css-b8ldur-Input{
    color: #6c757d !important;
}
.description-validation{
    color: #f35421;
    padding-top: 5px;
}
.prms-table{
    min-width: 100%;
     width: auto
}
.dropdown-class{
    display: flex;
    gap: 10px
}
.month-dropdown-width{
width: 130px
}
.year-dropdown-width{
    width: 80px
}