.navbar-container {
    &-log-in{
		position: absolute;
		top: 4%;
		right: 2%;
		z-index: 100000;
		text-decoration: none;
		color: #343f49;
	}
    &-login-page{
        height: 100% !important;
        background: #e2dff6;
    }
    &-welcome-page{
        height: 100% !important;
        width: 100%;
        display: flex !important;
        text-align: center;
    }
}