.sc {
    &-alert{
        position: fixed !important;
        top: 1% !important;
        right: 10% !important;
        left: 10% !important;
        height: 100% !important;
        width: 70% !important;
        z-index: 100000 !important;
    }
    &-alertHeading{
        font-size: 1.2rem !important;
        text-align: center !important;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    &-nodata{
        text-align: center !important;
        position: relative;
        top: 50%;
        padding-left: 1rem;
        width: 100%;
        margin-left: 40rem;
        font-weight: bolder;
    }
}