.processvine
{
   &-project {
    text-decoration: underline;
   }
   &-no-projects{
    position: fixed;
    top: 52%;
    left: 52%;
   }
   &-spinner {
        position: fixed;
        left: 50%;
        top: 50%;
        z-index: 999999;
    }

    &-overlay-box {
        position: fixed;
        backdrop-filter: blur(3px);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: .95;
        z-index: 999999;
        height: 100%;
        width: 100%;
    }

    &-table-header {
        border-collapse: collapse;
        height: 100px;
        padding-top: 0% !important;
        margin-top: 2.023%;
    }

    &-table-header thead th {
        position: sticky;
        top: 0;
        background-color: #F6F6F6;
        overflow: hidden;

        @include dark-mode {
            background-color: #252731;
        }
    }

    &-table-header tbody th td {
        overflow-y: scroll;
        left: 0;
    }
    

    &-clearfilter {
        position: relative;
        border: none;
        background-color: transparent;
        color: #999;
        font-size: 8px;
        padding: 0;
        margin: 0px 4px;
    }

    &-multiSelect-optionContainer {
        .highlight {
            background: #4D69FA;
            color: #fff;
        }

        .optionListContainer {
            li:hover {
                background: #4D69FA !important;
                color: #fff !important;
            }

            @include dark-mode {
                background-color: #252731;
            }
        }

        .chip {
            background: #4D69FA;
        }

        .searchBox {
            @include dark-mode {
                color: white;
            }
        }
    }

    &-scrollmenu {
        overflow: auto;
        white-space: nowrap;

        small {
            display: inline-block;
            color: white;
            text-align: center;
            padding: 14px;
            text-decoration: none;
        }

        small:hover {
            background-color: #777;
        }
    }

    @media screen and (min-width: 600px) and (max-width: 945px) {
        &-scrollmenu {
            small {
                margin-bottom: 1rem;
            }
        }
    }

    &-btn-close {
        display: none;
    }
    &-form-font-weight {
        font-weight: 400;
    }
    &-project-form-format{
        width: 380px;
    }
}

.kpi-container {
    color: #323232;

    &-body {
        padding: 1rem 1.25rem;
    }

    &-childKpiWrapper {
        border-bottom: 1px solid gainsboro;
        padding-bottom: 10px;
        display: flex !important;
        gap: 20px;
        justify-content: center;
    }

    &-btnCorpvine {
        color: #fff;
        border: 0;
        cursor: pointer;
        width: 7.5rem;
        height: 2.5rem;
        font-weight: normal;
        border-radius: 1.25rem;
        padding: 0.375rem 0.75rem;
        text-align: center;

        &:hover {
            background: #0090cc;
        }
    }

    &-kpiStatus {
        left: 1.25rem;
        position: relative;
    }

    &-kpiViewLogBtn {
        flex: 1;
    }

    &-score {
        width: 50px;
        padding: 4.2px 0.2px;
        font-size: 1.05em;
        font-weight: 600;
    }

    &-info {
        display: inline-block;
        margin-left: 10px;
    }

    &-comments {
        display: block;
    }

    &-childKpiCurrentScore {
        height: 15%;
        padding: 3.2px 0.2px;
        flex: 1;
        max-width: 135px;
    }

    .auditbubble {
        width: 40% !important;
        margin-right: 10px;
    }

    .kpi-name-audit-container {
        width: 84%;
    }

    .kpi-auditPeriod {
        display: flex;
        flex-direction: column;
        height: 32%;
        padding: 3.2px 0.2px;
        font-size: 1.05em;
        font-weight: 600;
        line-height: 1.2;
        margin-bottom: 0.5rem;
        margin-top: 2px;
        flex: 1;
        max-width: 125px;
    }

    .auditQuarter {
        background-color: rgb(0, 176, 240, 255) !important;
        color: #fff;
    }

    .auditYearly {
        background-color: rgb(4, 132, 172) !important;
        color: #fff;
    }

    .auditHalf {
        background-color: rgb(68, 192, 216) !important;
        color: #fff;
    }

    .auditNa {
        background-color: rgb(4, 84, 116) !important;
        color: #fff;
    }

    &-childKpiTitle {
        flex: 1;
        word-wrap: break-word;
        min-width: 100px !important;
     
        &:hover, &:focus {
            text-decoration: dotted;
            color: #4D69FA;
            font-weight: 600;
            cursor: pointer;
        }
    }

    &-alert {
        position: fixed !important;
        top: 1% !important;
        right: 35% !important;
        height: 6% !important;
        width: 30% !important;
        z-index: 100000 !important;
    }

    &-alertHeading {
        font-size: 1.2rem !important;
        text-align: center !important;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &-view-log {
        padding: 8px;
    }

    &-message {
        text-align: center;
        color: red;
    }

    &-actionby {
        color: #777e86;
        font-size: 0.8rem;
        display: block;
    }

    &-actionby-theme {
        color: white !important;
    }

    &-details {
        // padding: 0.78rem;
        height: 34px;
        display: flex;
        align-items: center;
    }

    &-kpi-score {
        margin-left: 6px;
    }

    &-bg-orange {
        background-color: #ff7603;
    }

    &-bg-grey {
        background-color: grey !important;
    }

    &-kpiRequestBtn {
        flex: 1;
        min-width: 110px;
    }

    @media screen and (max-width: 1100px) {
        &-alert {
            width: 37% !important;
        }

        &-childKpiWrapper {
            gap: 10px;
        }

        &-alertHeading {
            font-size: 1rem !important;
        }

        &-childKpiCurrentScore {
            min-width: 120px;
            font-size: 12px;
            padding: 5px 3px;
        }

        &-childKpiTitle {
            font-size: 12px;
        }

        .kpi-auditPeriod {
            min-width: 100px;
        }
    }

    @media screen and (max-width: 800px) {
        &-childKpiTitle {
            min-width: 100px !important;

        }

        &-kpiRequestBtn {
            min-width: unset !important;
        }

        .kpi-auditPeriod {
            padding: 3.2px 6px;
            min-width: 90px;
        }
    }


    @media screen and (max-width: 445px) {
        &-childKpiCurrentScore {
            width: 102px !important;
            font-size: 8px !important;
        }

        &-childKpiTitle {
            width: 90px;
        }
    }

    @media screen and (max-width: 425px) {
        &-childKpiCurrentScore {
            width: 80px !important;
            font-size: 8px !important;
        }

        &-childKpiTitle {
            width: 90px;
        }

        &-body {
            padding: 1rem 0.2rem;
        }
    }

    &-block {
        display: block;
    }
}

.offcanvas {
    z-index: 100000 !important;
}
.popover-string{
	cursor: pointer;
	text-decoration: none;
}

.bg-kpi {
    &-request {
        background-color: #FF5AAE;
        color: #FFFFFF;
    }

    &-approve {
        background-color: #674EA7;
        color: #FFFFFF;
    }

    &-finalize {
        background-color: #6AA84F;
        color: #FFFFFF;
    }

    &-rejected {
        background-color: #FF0000;
        color: #FFFFFF;
    }

    &-not_started {
        background-color: #7a7a7a;
        color: #FFFFFF;
    }
}

.score { font-weight: 500; }
.score-red { color: #FF0000; }
.score-orange { color: #FF9900; }
.score-yellow { color: #ffc700; }
.score-green { color: #6AA84F; }
.score-grey { color: grey; }

.kpi-activity {
    padding-top: 10px;

    .activity-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
    
        .line {
            width: 100%;
            height: 1px;
            background-color: #0090cc;
        }
    
        .activity-title {
            color: #0090cc;
            font-weight: bold;
        }
    }

    .date-time {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        padding-top: 10px;

        .date {
            padding: 5px 10px;
            background-color: #0090cc;
            color: #FFFFFF;
            border-radius: 5px;
            display: inline;
        }
    }

    .score {
        font-size: 30px;
    }

}

.project-search-input-container {
    border: 1px solid #878787 !important;
}

.project-search-input-container.border-blue {
    border-color: #6c5dd3 !important;
}

.no-activity {
    padding: 30px 0;
}

.save-button-container {
    position: fixed;
    bottom: 0;
    background-color: #FFF;
    width: calc(100% - 2rem);
    padding-bottom: 10px;
}

[theme=dark] .save-button-container {
    background-color: #1d1f27;
}