//
//  App
//

.app {
	display: flex;
	flex: 1 0 auto;
	flex-direction: column;
	// Mobile Status
	@include media-breakpoint-down(map-get($aside, mobile-breakpoint)) {
		overflow: hidden;
	}
	&-log-in{
		background: #e2dff6 !important;
		position: absolute;
		top: 4%;
		right: 2%;
		z-index: 100000;
		text-decoration: none;
		color: #343f49;
	}
	&-log-out{
        background-color: #0d8eb9 !important;
        border-color: #0d8eb9 !important;
        padding: 0.6rem !important;
    }
	&-log-in-transparent-cover {
		width: 100%;
		height: 100%;
		background-color: transparent;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 999999999;
	}
	&-edit{
		position: absolute;
		right: 35px;
		top: 25px;
		// z-index: 10000;
		text-decoration: none !important;
		background: #0d8eb9;
		padding: 0.6rem;
	}
	.employee-details-colone{
		width: 500px;
	}
	.employee-details-coltwo{
		width: 170px;
	}
	@media screen and (min-width: 768px) and (max-width: 990px) {
		&-log-out, &-edit {
			right: 4px;
			top: 17px;
			padding: 0.4rem;
			font-size: 12px;
		}
	}
	@media screen and (max-width: 765px){
		&-log-out, &-edit {
			font-size: 12px;
			right: 9px;
			top: 12px;
		}
		.mobile-header-menu{
			margin-right: 75px;
		}
	}
	.userData{
		float: right;
	}
	.align-items-center{
		text-align: center;
	}
	.statusFilter{
		margin-top: 5%;
		margin-left: 200%;
		width: 130px;
	}
	.empStatusSelect{
		height: 36px;
		background-color: #f8f9fa;
	}
	.filterStatus{
		height: 30px;
		width: 20%;
	}
	.empstatus-filter{
		font-size: 1rem;
		margin-top: 3px;
	}
	.quarter-fix {
		width: 12%;
	}
	.text-right {
		text-align: right;
	}
	.textalign_score{
		padding: 1rem 2rem;
	}
	.textalign_other{
		padding: 1rem 3rem;
	}
	.sc-label-fixes {
		margin-left: 5px;
		padding-bottom: 5px;
		padding-top: 5px;
	}
	.div-text-box {
		border-radius: 15px;
		background-color: #f8f9fa;
		margin-top: 5px;
		border: 0.5px solid #cbc3c3;
		border-bottom: 0.5px solid #cbc3c3 !important;
		@include dark-mode {
			background-color: #252731;
			border: 0.5px solid #cbc3c3;
		}
	}
	.history-fix {
		margin-top: 10px;
		margin-bottom: 10px;
	}
	.pagination__desc {
		@include dark-mode {
			color: $gray-200;
		}
	}
	.left-con-details {
		float: right;
	}
	.form-select-sm {
		@include dark-mode {
			background-color: #252731 !important;
			border: 0.5px solid #cbc3c3 !important;
			background-blend-mode: exclusion;
		}
	}
	.prob_tooltip{
		pointer-events: none;
		position: fixed;
	}
	.sc-detailed-notes label {
		margin-left: 5px;
		padding-top: 5px;
		color: #1f2128 !important;
		font-weight: bold !important;
		@include dark-mode {
			color: #fff !important;
		}
	}
	.sc-detailed-notes-noedit label {
		margin-left: 5px;
		padding-top: 5px;
		color: #1f2128 !important;
		font-weight: bold !important;
		@include dark-mode {
			color: #fff !important;
		}
	}
	.sc-detailed-notes-noedit :focus {
		border: none;
		box-shadow: none;
	}
	.probation-edit {
		min-height: 2.5rem;
		  }
}
