.cgp {
    &-popover {
        z-index: 999999 !important;
    }

    &-businessname:hover {
        color: #0d8eb9;
    }

    &-no-skill {
        position: fixed;
        top: 52%;
        left: 46%;
    }

    &-spinner {
        position: fixed;
        left: 50%;
        top: 50%;
        z-index: 999999;
    }

    &-overlay-box {
        position: fixed;
        backdrop-filter: blur(3px);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: .95;
        z-index: 999999;
        height: 100%;
        width: 100%;
    }

    &-table-header {
        border-collapse: collapse;
        height: 100px;
        padding-top: 0% !important;
        margin-top: 2.023%;
    }

    &-table-header thead th {
        position: sticky;
        top: 0;
        background-color: #F6F6F6;
        overflow: hidden;
        z-index: 1;

        @include dark-mode {
            background-color: #252731;
        }
    }

    &-table-header tbody th td {
        overflow-y: scroll;
        left: 0;
    }

    &-clearfilter {
        position: relative;
        border: none;
        background-color: transparent;
        color: #999;
        font-size: 8px;
        padding: 0;
        margin: 0px 4px;
    }

    &-search {
        background: #0d8eb9;
        border-color: cadetblue;
    }

    &-multiSelect-optionContainer {
        .highlight {
            background: #4D69FA;
            color: #fff;
        }

        .optionListContainer {
            li:hover {
                background: #4D69FA !important;
                color: #fff !important;
            }

            @include dark-mode {
                background-color: #252731;
            }
        }

        .chip {
            background: #4D69FA;
        }

        .searchBox {
            @include dark-mode {
                color: white;
            }
        }
    }

    &-scrollmenu {
        overflow: auto;
        white-space: nowrap;

        small {
            display: inline-block;
            color: white;
            text-align: center;
            padding: 14px;
            text-decoration: none;
        }

        small:hover {
            background-color: #777;
        }
    }

    @media screen and (min-width: 600px) and (max-width: 945px) {
        &-scrollmenu {
            small {
                margin-bottom: 1rem;
            }
        }
    }

    &-btn-close {
        display: none;
    }
}

.cgp-container {
    color: #323232;

    &-body {
        padding: 1rem 1.25rem;
    }

    &-childKpiWrapper {
        border-bottom: 1px solid gainsboro;
        padding-bottom: 10px;
        display: flex !important;
    }

    &-btnCorpvine {
        color: #fff;
        border: 0;
        cursor: pointer;
        width: 7.5rem;
        height: 2.5rem;
        font-weight: normal;
        border-radius: 1.25rem;
        padding: 0.375rem 0.75rem;
        text-align: center;

        &:hover {
            background: #0090cc;
        }
    }

    &-kpiStatus {
        left: 1.25rem;
        position: relative;
    }

    &-kpiViewLogBtn {
        width: 53% !important;
    }

    &-score {
        width: 35%;
        padding: 9.2px 4.2px;
    }

    &-info {
        display: inline-block;
        margin-left: 10px;
    }

    &-comments {
        display: block;
    }

    &-childKpiCurrentScore {
        height: 15%;
        width: 55%;
        padding: 11.2px 4.2px;
    }

    &-childKpiTitle {
        width: 84%;
    }

    &-alert {
        position: fixed !important;
        top: 1% !important;
        right: 35% !important;
        height: 6% !important;
        width: 30% !important;
        z-index: 100000 !important;
    }

    &-alertHeading {
        font-size: 1.2rem !important;
        text-align: center !important;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &-view-log {
        padding: 2px;
    }

    &-review-log {
        margin-left: -10%;
        overflow: auto;
        height: 35rem;
    }

    &-accordian {
        overflow: auto;
        height: 100%;
    }

    &-accordian-title {
        margin-top: 10px;
    }

    &-reviewheader-log {
        margin-left: -2%;
        margin-top: 10px;
    }

    &-message {
        text-align: center;
        color: red;
        margin-top: 8px;
    }

    &-rwmessage {
        text-align: center;
        color: #6154be;
        font-weight: 600;
        vertical-align: middle !important;
    }

    &-commentsmessage {
        text-align: left;
        color: red;
        margin-top: 5px;
        margin-top: 8px;
    }

    &-actionby {
        color: #777e86;
        font-size: 0.8rem;
        display: block;
    }

    &-actionby-theme {
        color: white !important;
    }

    &-details {
        // padding: 0.78rem;
        height: 34px;
    }

    &-align-popover {
        margin-left: 6px;
    }

    &-bg-green {
        background-color: #008000 !important;
        color: #fff;
    }

    &-bg-yellowgreen {
        background-color: #9ACD32 !important;
        color: #fff;
    }

    &-bg-orange {
        background-color: #FFA500 !important;
        color: #fff;
    }

    &-bg-lightred {
        background-color: #ea6854 !important;
        color: #fff;
    }

    &-bg-red {
        background-color: #FF0000 !important;
        color: #fff;
    }

    &-bg-blue {
        background-color: #ff00b0 !important;
    }

    &-bg-darkyellow {
        color: #8B8000 !important;
    }

    &-bg-dblue {
        background-color: #4d69fa !important;
        color: #fff;
    }

    &-bg-cblue {
        background-color: #0484ac !important;
        color: #fff;
    }

    &-bg-dyellow {
        background-color: #f0b800 !important;
        color: #fff;
    }

    &-bg-dgreen {
        background-color: #619a2d !important;
        color: #fff;
    }

    &-bg-lorange {
        background-color: #ff7603 !important;
        color: #fff;
    }

    &-bg-rgreen {
        background-color: rgb(68, 192, 216) !important;
        color: #fff;
    }

    &-kpiRequestBtn {
        width: 100%;
    }

    &-bg-grey {
        background-color: grey !important;
        color: #fff;
    }

    &-bg-req {
        background-color: #25A5C5 !important;
        color: #BBF1FF;

    }

    &-bg-app {
        background-color: #B8E994 !important;
        color: #3A780C;

    }

    &-bg-ach {
        background-color: #079976 !important;
        color: #fff;

    }

    &-bg-prc-hr-app {
        background-color: #009E60 !important;
        color: #fff;

    }

    &-bg-gradeC {
        background-color: #FFEA2C !important;
        color: #B7A607;

    }

    &-bg-in {
        background-color: #FCBD1A !important;
        color: #AC7710;

    }

    &-bg-non {
        background-color: #E98339 !important;
        color: #FFF5DB;

    }

    &-bg-gradeF {
        background-color: #B71540 !important;
        color: #FFDCE5;

    }

    &-bg-DHD {
        background-color: #FF7979 !important;
        color: #901133;

    }

    &-bg-HRD {
        background-color: #DF1F25 !important;
        color: #FFD8DA;

    }

    &-bg-good {
        background-color: #FCBD1A !important;
        color: #AC7710;
    }

    &-bg-progress {
        background-color: #913000 !important;
        color: #FFD8DA;
    }

    &-bg-gm {
        background-color: #89418F !important;
        color: #F6B7FC;

    }

    @media screen and (max-width: 676px) {
        &-alert {
            width: 37% !important;
        }

        &-alertHeading {
            font-size: 1rem !important;
        }

        &-childKpiCurrentScore {
            width: 750px;
            padding: 8.2px 3.2px;
        }

        &-childKpiTitle {
            width: 750px;
        }
    }

    @media screen and (max-width: 445px) {
        &-childKpiCurrentScore {
            width: 102px !important;
            font-size: 8px !important;
        }

        &-childKpiTitle {
            width: 90px;
        }
    }

    @media screen and (max-width: 425px) {
        &-childKpiCurrentScore {
            width: 80px !important;
            font-size: 8px !important;
        }

        &-childKpiTitle {
            width: 90px;
        }

        &-body {
            padding: 1rem 0.2rem;
        }
    }

    &-block {
        display: block;
    }
}

.offcanvas {
    z-index: 100000 !important;
}

.text-nowrap {
    margin-right: 10px;
}

.lt {
    text-align: left;
}

.rt {
    text-align: right;
}

@media screen and (min-width: 992px) {
    .modern-design .subheader {
        margin-left: 0.55rem;
        margin-right: 0.55rem;
    }
}

.hg {
    margin-left: 0.1rem !important;
    margin-right: 0.1rem !important;
}

.bcrumb {
    display: none;
}

.align-subheader-left {
    margin-left: -19px;
}

.text-center {
    text-align: left;
}

.reqweight {
    display: none;
}

.required label::after {
    color: red;
    content: " *";
    font-size: 15px;
}

.wd-l7 {
    width: 7% !important;
}

.wd-l8 {
    width: 8% !important;
}

.wd-l9 {
    width: 9% !important;
}

.wd-10 {
    width: 10% !important;
}

.wd-11 {
    width: 11% !important;
}

.wd-12 {
    width: 12% !important;
}

.wd-14 {
    width: 14% !important;
}

.wd-25 {
    width: 25% !important;
}

.wd-30 {
    width: 30% !important;
}


.vt-top {
    vertical-align: top !important;
}

.boldtext {
    font-weight: 400;
}

.cgp-bdg {
    padding: 2px;
    border-radius: 0.5rem;
    font-weight: 600;
}

.offcanvas-header {
    padding-bottom: 0;
}

.req-weightage {
    background-color: #e9ecef;
}

.reviewbody {
    padding-top: 0;
}

.commentbox {
    border: 1px solid #cbc7c7;
    padding: 5px;
    border-radius: 10px !important;
    contain: inline-size;
    width: 207%;
    min-height: 64px;
}

.breadcrumb-item:first-child .breadcrumb-icon {
    display: none;
}

.breadcrumb {
    padding: 11px;
}

.numbertext {
    text-align: right;
}

.card-body {
    padding: 7.5px;
}

.align-title {
    margin-left: 8px;
}

.align-reviewhistory {
    padding: 17.5px;
    padding-top: 0px;
}

.align-dvalues {
    width: 121%;
    margin-right: 59px;
}

.align-updatedon {
    height: 51px !important;
}

.align-reviewdetails {
    width: 170% !important;
}

.align-inputbox {
    width: 100% !important;
}

.align-subheaderright {
    width: 250px;
    border: 0.5px solid #cbc3c3;
    border-radius: inherit;
}

.label-color {
    font-weight: normal;
    color: unset;
}

.align-status {
    margin-top: 5px;
    margin-bottom: 1px;
}

.value-size {
    font-weight: 600;
}

.align-newposition {
    position: absolute;
    right: 5px;
    background: #0d8eb9;
}

.btn-bg {
    background: #0d8eb9;
}

.align-position {
    position: absolute;
    // right: 0px;
    // margin-left: 130px;
    background: #0d8eb9;
}

.align-saveposition{
    position: absolute;
    background: #0d8eb9;

    // right: 0px;
}

.align-filterbox {
    display: flex;
}

.align-column-filtericon {
    align-items: center;
}

.align-editstatus {
    margin-bottom: 11px;
    font-size: medium;
    margin-left: -5px;
}

.collapse_reqweight {
    font-size: 1rem !important;
}

.info-size {
    font-size: 11px !important;
}

.div-text-box {
    border-radius: 15px;
    background-color: #f8f9fa;
    margin-top: 5px;

    @include dark-mode {
        background-color: #252731;
        border: 0.5px solid #cbc3c3;
    }
}

.tbl-hd {
    padding: 1rem 0.2rem !important;
    vertical-align: bottom !important;
}

.tbl-hd-tp {
    padding: 1rem 0.2rem !important;
    vertical-align: top !important;
}


@media (min-width: 1400px) {
    .container-xxl {
        max-width: 1320px;
    }

    .container-cgp {
        max-width: none !important;
    }
}

.cardstyle {
    width: 70%;
}

.tmg {
    margin-right: 5px !important
}

.sh,
.MuiOutlinedInput-root {
    border-radius: 15px !important;
}

.tableheader {
    margin-top: unset !important;
}

.toast-container {
    z-index: 9999;
}

.close {
    height: 0px !important;
}

.ct {
    text-align: center;
}

.input-text-box {
    border-radius: 15px;
    background-color: #f8f9fa;
}

div:focus-within>.div-text-box {
    border: thin solid #4d69fa !important;
    transition: border-color 0.15s ease-in-out;
}

.input-focus:focus-within {
    border: thin solid #4d69fa !important;
    transition: border-color 0.15s ease-in-out;
}

.searchicon {
    margin-right: 8px;
}

.cbx {
    margin-right: 10px;
    margin-left: 4px;
    vertical-align: middle !important;
}

.icon-border {
    --bs-btn-border-width: none !important;
    background: transparent;
    --bs-btn-hover-bg: none !important;

    @include dark-mode {
        background: transparent;
    }
}

.align-dm,
.modal-content,
.MuiInputBase-root {
    @include dark-mode {
        background-color: #252731 !important;
        border: 0.5px solid #cbc3c3 !important;
        background-blend-mode: screen !important;
    }
}

.align-closebtn {
    font-size: 9px !important;
}

.agree-cbx {
    margin-bottom: 11px !important;
}

.align-pagination-dm {
    @include dark-mode {
        background-color: #252731 !important;
        background-blend-mode: screen !important;
        color: white !important;
    }
}

.column {
    float: left;
    width: 33.33%;
    padding: 5px;
}

.row::after {
    content: "";
    clear: both;
    display: table;
}

.view-subheader {
    background: #a9cdda;
    margin-top: 10px;
}

.view-content {
    margin-top: 6px;
    margin-bottom: 6px;
}

.view-cggrow {
    margin-left: -5px;
}

.skill-table-header {
    margin-top: unset !important;
}

.accordion-button-primary:not(.collapsed),
.MuiAutocomplete-clearIndicator,
.MuiAutocomplete-popupIndicator,
.MuiAutocomplete-input {
    @include dark-mode {
        color: white !important;
    }
}

.dd-cgg {
    margin-bottom: 11px !important;
}

.btn-cgg {
    width: 30% !important;
    margin-left: 290px !important;
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dd-cgg-card {
    margin-left: -12px;
}

.sub-header-filter {
    margin-left: -1.45rem !important;
    margin-right: -1.45rem !important;
}

.sub-header-tl {
    margin-left: -1.95rem !important;
    margin-right: -1.95rem !important;
}

.bg-gray {
    color: #6c757d;
}

.MuiAutocomplete-input {
    padding: 3px !important;
}

.btn-save {
    background: #0d8eb9;
}

.btn-outline-info {
    --bs-btn-hover-color: #0d8eb9;
}

.btn-color {
    --bs-btn-color: #0d8eb9 !important;
    --bs-btn-border-color: #0d8eb9 !important;
    --bs-btn-hover-color: #0d8eb9 !important;

    @include dark-mode {
        --bs-btn-color: white !important
    }
}

.gr-st {
    font-size: 1.05em;
    width: 100% !important;
    text-wrap: wrap !important;
    overflow-wrap: anywhere;
}

.cgp-gr {
    flex-direction: column;
    font-size: 1.05em;
    font-weight: 600;
}

.sub-input-align {
    margin-bottom: 9px;
    margin-top: 9px;
}

.autoDD {
    margin-top: 9px;
}

.editpopup-status {
    flex-direction: column;
    padding: 3.2px 0.2px;
    font-size: 1.05em;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 0.5rem;
    margin-top: 2px;
}

.cgp-select {
    font-weight: unset !important;
}

.MuiAutocomplete-endAdornment {
    top: calc(56% - 14px) !important
}

.MuiInputLabel-root {
    top: -3px !important;

    @include dark-mode {
        color: white !important
    }
}

.pos-ledgend {
    margin-left: 950px !important;
}

.barheader {
    width: 1% !important
}

.barcell {
    height: 100% !important;
    padding: 0 !important;
}

.nextbar {
    padding: 0 !important;
}

.btn-update {
    margin-right: 59px !important;
}

.cell_break {
    word-wrap: break-word;
}

.pos-comments {
    width: 336% !important;
}

.pos-reviewcomments {
    width: 410% !important;
}

.file-link {
    @include dark-mode {
        fill: white !important
    }
}

.skill-edit {
    padding-top: 10px !important;
}

.icon-color {
    .svg-icon--material {
        fill: #0d8eb9 !important;

        @include dark-mode {
            fill: white !important
        }
    }

    fill: #0d8eb9 !important;

    @include dark-mode {
        fill: white !important;
    }
}

.icon-link {
    @include dark-mode {
        stroke: white !important;
    }
}

.goal-dd {
    width: 90% !important;
}

#cgp-chart,
.apexcharts {
    max-height: 40px;
    // margin-left: -15px;
    margin-top: -40px;
    display: flex;
    border-radius: 1rem;
    background: transparent;
}

.apexcharts-data-labels text {
    font-size: 9px;
    vertical-align: middle;
    text-wrap: wrap !important;
    overflow-wrap: anywhere;
}

.apexcharts-treemap-rect {
    height: 30px;
    stroke: transparent;
    stroke-width: unset;
}

.table-modern > tbody > tr > *, .table-modern > thead > tr > * {
    padding: 1rem 0.2rem;
    vertical-align: middle;
}

.first-hd-col{
    padding-left: 6px !important;
}

.last-hd-col{
    padding-right: 5px !important;
}

.first-col{
    padding-left: 8px !important;
}
.mid-col{
    padding-right: 8px !important;
}

.last-col{
    padding-right: 4px !important;
}

.num-pad-right{
    padding-right:7px !important;
}

.kyp-padding{
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.col-line-height {
    line-height: 16px;
}

.br-none{
    border: none;
}

.inactive-text{
    display: flex;
    align-items: center;
    background-color: #e9ecef; // #f8f9fa;
    width: 100%;
    border-radius: 13px;
    padding: 0px 10px;
    min-height: 34px;
    border:1px solid #eeeeef;
}

.lbl-mg-bot{
    margin-bottom: 8px;
}

.lbl-mg-top{
    margin-bottom: 22px;
}

.mg-top{
    margin-bottom: 10px;
}

.edit-btn{
    position: sticky;
    max-width: 200px;
    top: 0;
    z-index: 1000;
    background: white;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    float: right;
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;
}
.edit-btn:hover{
    background-color: #4d69fa;
    color: white;
}
.dropdown-size{
    padding: 22px;
    cursor: pointer !important;
    margin-bottom: 4px;
}
.final-save{
    display: flex;
    flex-direction: row-reverse; 
    margin-top: 13px;
    margin-right: 13px;
}
.tbl-style{
    text-align: center;
}

// CSS for highlighted option
.highlighted-option {
    background-color: #e0f7fa; /* Light blue background for selected options */
    font-weight: bold;
  }
  
  .highlighted-control {
    background-color: #d4edda; /* Light green background */
    border-color: #155724;    /* Dark green border */
  }
  
  .dropdown-size {
    position: relative; /* Ensure the dropdown is positioned relative to this element */
  }
  
  .dropdown-size .dropdown-menu {
    position: absolute; /* Position the dropdown menu absolutely */
    top: 100%; /* Position it below the input */
    left: 0; /* Align it to the left */
    z-index: 1000; /* Ensure it appears above other elements */
    width: 100%; /* Match the width of the input */
  }

  .cgg-change-btn{
    position: absolute;
    top: 0px;
    z-index: 100;
    // padding: 10px;
    width: 19%;
    border: 2px;
    margin-left: 82%;
  }

  .center-toast{
    position: fixed; /* Position fixed relative to the viewport */
    top: 50%;  /* Vertically center */
    left: 50%;  /* Horizontally center */
    transform: translate(-50%, -50%); /* Adjust for perfect centering */
    z-index: 9999; /* Ensure the toast is above other content */
}

.cancel-confirm-box{
    z-index:  100000;
    position:fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content:center;
    align-items: center;
}

.cancel-confirm-container{
    background-color: #FFFFFF; // Modal background color changed to white
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    text-align: center;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,Cantarell,'Open Sans','Helvetica Neue',sans-serif;
}
.cancel-confirm-container{
@include dark-mode {
    background-color: #252731;
}
}

.cancel-confirm-btn-container{
    display: flex;
    gap: 8px;
    justify-content: center;
    margin-top: 8px;
}

.cancel-confirm-btn{
    padding: 4px 8px;
    background-color: #0d8eb9;
    color: #FFFFFF;
    border: none;
}

.multi-select-border{
    border: 0px !important;
}

.dark-mode-change-cgg{
    @include dark-mode {
        background-color: #252731;
        color: #FFFFFF !important;
    }   
}
.add-item-popup{
    @include dark-mode {
        background-color: #252731;
        color: #FFFFFF !important;
    }   
}

.add-item-popup-remove-darkmode{
    @include dark-mode {
        background-color: #ffff;
        color: #252731;
    }   
}

.cgg-dialog-margin{
    margin: 0px 5%;
    border-radius: 1rem;
}

.multi-select-color{
    color: black;
}

.multiSelectContainer li
{
    padding: 10px;
    color: black;
}

.fixed-footer-card {
    display: flex;
    flex-direction: column;
    height: 100vh; /* Full viewport height, adjust as needed */
    max-height: 670px; /* Set to the maximum height you need */
    position: relative; /* Necessary for absolute positioning */
    overflow: hidden; /* Prevents footer from overlapping content */
}

.scrollable-card-body {
    flex: 1;
    overflow-y: auto;
    padding: 1rem;
    margin-bottom: 60px; /* Adjust to the height of the footer to prevent overlap */
    box-sizing: border-box;
}

.fixed-card-footer {
    position: absolute;
    bottom: -33px;
    left: 0;
    width: 100%;
    // background-color: #f9f9f9; /* Adjust as needed */
    border-radius: 1rem;
    padding: 1rem;
    z-index: 10; /* Keeps it above scrolling content */
    height: 60px; /* Ensure this matches margin-bottom in .scrollable-card-body */
}

.zindex-auto
{
    z-index: auto;
}

.cgg-tpc
{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
}

.tpc-fs-15
{
    font-size: 15px;
}
//For edited cell highlight
.highlight-changed {
    background-color: #f9fcb7; /* Light yellow background for edited fields */
    transition: background-color 0.3s ease; /* Smooth transition */
  }
  
// For new cell hightlight
  .highlight-new {
    background-color: #b7fcbf; /* Light yellow background for edited fields */
    transition: background-color 0.3s ease; /* Smooth transition */
  }

  .confirmation-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .confirmation-table th, 
  .confirmation-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .confirmation-table th {
    background-color: #f4f4f4;
  }
  
  .undo-button {
    background: none;
    border: none;
    color: #0056b3;
    cursor: pointer;
    font-size: 25px;
    padding: 5px;
    transition: color 0.3s ease;
    margin-left: 12px;
  }
  
  .undo-button:hover {
    // color: #0056b3;
  }
  
  .undo-button:focus {
    outline: none;
  }

  .undo-position {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  
  .wrap-url {
    word-wrap: break-word;
    word-break: break-word;
  }
  
  
.center-modal {
display: flex;
align-items: center;
justify-content: center;
min-height: 100vh;
}
  
.center-modal .modal-dialog {
margin: 0;
position: fixed;
top: 50%;
left: 50%;
min-height: 100vh;
// transform: translate(-50%, -50%);
}

.file-upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: 2px dashed #007bff;
    border-radius: 8px;
    cursor: pointer;
    background-color: #f9f9f9;
    transition: background-color 0.3s ease;
  }
  
  .file-upload-container:hover {
    background-color: #eef5ff;
  }
  
  .file-upload-label {
    font-size: 16px;
    color: #007bff;
    margin-top: 10px;
  }
  
  .hidden-file-input {
    display: none;
  }
  
  .multiselect-custom div
  {
    border: none;
    border-radius: 5px;
    padding: 8px;
    padding-top: 10px;
  }

/* Container for the table */
.table-container {
    max-width: 100%;
    // margin: 20px auto;
    font-family: Arial, sans-serif;
    border: 1px solid #ddd;
  }
  
  /* Table Styles */
  .styled-table {
    width: 100%;
    border-collapse: collapse;
    margin: 0;
    font-size: 16px;
    text-align: left;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
  }
  
  /* Header Row Styles */
  .styled-table thead tr {
    background-color: #4caf50;
    color: #ffffff;
    text-align: left;
  }
  
  /* Table Cell Styles */
  .styled-table th, 
  .styled-table td {
    padding: 12px 15px;
    border: 1px solid #ddd;
  }
 
.cgg-upload-error{
    color: red;
    border-radius: 10px;
}
  
// Dropdown icon
  .custom-select-container {
    position: relative;
    display: inline-block;
    width: 100%; /* Adjust as needed */
  }
  
  .custom-select-container select {
    appearance: none; /* Removes default arrow */
    -webkit-appearance: none; /* For Safari */
    -moz-appearance: none; /* For Firefox */
    padding-right: 2rem; /* Adds space for the custom arrow */
    // margin-top: 15px;
  }
  
  .custom-select-container::after {
    content: ""; /* Dropdown arrow symbol */
    position: absolute;
    right: 1rem; /* Adjust position as needed */
    top: 67%;
    transform: translateY(-50%);
    pointer-events: none; /* Prevents arrow from being interactive */
    font-size: 0.8rem; /* Adjust size as needed */
    color: #000; /* Adjust color as needed */
    width: 12px; /* Adjust width to your desired size */
    height: 12px; /* Adjust height to your desired size */
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27none%27 stroke=%27black%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
    background-size: contain;
    background-repeat: no-repeat;
    }
  // End of dropdown icon

  .field-position {
    padding-top: inherit;
    display: flex;
  }

  .field-position-grade {
    // padding-top: inherit;
    padding-top: 12px;
    display: flex;
  }

  .add-input-field {
    width: 100%;
  }
// confirmation popup close button
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    font-weight: bold;
    color: #000; /* Change color as needed */
    cursor: pointer;
  }
  
  .close-button:hover {
    color: #2e2c2c; /* Red hover color */
  }
  
  .tp-dailog-container
  {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-around;
    align-items: center;
  }

  .tp-fnt
  {
    font-size: 15px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }

  .upload-fnt
  {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }

  .last-row-container {
    position: sticky;
    bottom: 0;
    background-color: #fff; /* Ensure it's visible on top of other content */
    z-index: 10;
  }
  
  .norecord-position{
    position: absolute;
    right: 46%;
    top: 43%;
  }

  .upload-tp {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-around;
    align-items: center;
}

.cgg-header {
    padding: 10px;
}

.emp-list-fnt {
    font-size: 17px;
}
.cgg-sc-btn {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-end;
    position: relative;  /* Ensure proper stacking context */
    z-index: 10;        /* Add a z-index to bring it above other elements */
  }
  
  .preview-btn-note-ps {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
  }

  .cgg-icon-color {
    .svg-icon--material {
        fill: rgb(0 0 0 / 54%) !important;

        @include dark-mode {
            fill: white !important
        }
    }

    fill: rgb(0 0 0 / 54%) !important;

    @include dark-mode {
        fill: white !important;
    }
}

.dark-mode-change-cgg {
    @include dark-mode {
      .react-select__control {
        background-color: #252731;
        color: #ffffff !important; /* Text inside the control */
      }
  
      .react-select__menu {
        background-color: #252731;
      }
  
      .react-select__option {
        background-color: #252731;
        color: #ffffff !important;
  
        &:hover {
          background-color: #3b3f4a; /* Hover state for options */
        }
      }
  
      /* Ensure the selected value inside the dropdown is styled */
      .react-select__option--is-selected {
        background-color: #3b3f4a;
        color: #ffffff !important; /* Selected option text color */
      }
  
      /* Ensure the selected value in the control remains white */
      .react-select__single-value {
        color: #ffffff !important;
      }
    }
  }
  .icons-row
  {
    user-select: none;
  }
  .cgg-heading
  {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
  }