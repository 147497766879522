.custom-multiselect input {
    height: 100%; 
    padding: 0 5px; 
    line-height: 50px;   
  }

  .search-wrapper {
    border-radius: 15px;
    padding: 0.5rem; 
    background-color: #fff;
  }
  .enlarged-padding input {
    padding: 0.4rem;  /* Enlarged padding size */
  }