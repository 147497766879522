.modal-close-btn {
    position: absolute;
    top: 10px;
    right: 5px;
    z-index: 1;
    background-color: white;
    border-color: white;
    color: black;
}
 .cell-content {
  max-height: 200px;
  overflow: hidden;
  position: relative;
  padding-right: 20px;
 }
 
 .cell-content.expanded {
  white-space: normal;
  overflow: visible;
  position: static;
  max-height: none;
 }
 
 .read-more {
  color: blue;
  cursor: pointer;
  position: absolute;
  margin-right: '5px';
 }
 
 .read-more:hover {
   text-decoration: underline;
 }
 .add-url-modal-button {
  position: absolute;
  top: 10px;
  right: 5px;
  z-index: 1000;
  background-color: '#fff';
 }
 .url-delete-modal {
  display: flex;
  justify-content: center;
 }

.read-more:hover {
  text-decoration: underline;
}
.arch-components-column {
  width: 500px;
}
.aed-column-width {
  width: 650px;
}
.add-modal-button {
  position: absolute;
  top: 10px;
  right: 5px;
  z-index: 1000;
  background-color: '#fff';
 }
.image-view-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
}
.image-preview-modal {
    max-width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
}
.image-preview-modal img {
    width: 100%;
    height: 400px;
    display: block;
    margin: 0 auto;
}
.image-upload-modal {
    display: flex;
    justify-content: end;
    margin-left: -3px;
    margin-top: -3px;
}
.sortable-image-modal {
    display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 16px;
  max-width: 900px;
  margin: 2px 38px;
    padding-bottom: 30px;
}
.sortable-image-button {
  position: absolute;
  z-index: 1;
  top: -15px;
  right: -15px;
}
.upload-btn-modal {
    display: flex;
    margin-left: 760px;
    margin-top: -0.65rem;
  margin-bottom: 15px;
}
.image-upload-btn {
    display: block;
    margin-right: 10px;
}
.drag-overlay-modal {
    transform-origin: 0 0;
}
.uploaded-image-modal {
    border-radius: 8px;
    max-width: 100%;
}
.sortable-image {
    border-radius: 8px;
    max-width: 100%;
    box-shadow: rgba(63, 63, 68, 0.05) 0px 0px 0px 1px, rgba(34, 33, 81, 0.15) 0px 1px 3px 0px;
}
.sorted-image {
    border-radius: 8px;
    max-width: 100%;
    box-shadow: none;
}
.invalid-image-alert-modal {
  position: fixed;
  top: 1%;
  right: 35%;
  height: 13%;
  width: 50%;
  z-index: 100000;
}

.description-editor-light {
  height: 200px !important;
  max-height: 300px;
  border-radius: 15px 15px;
  background-color: rgb(245, 245, 245);
  padding-left: 10px;
  padding-right: 10px;
}

.description-editor-dark {
  height: 200px !important;
  max-height: 300px;
  background-color: #212529;
  border: 1px solid #343a40;
  box-shadow: inset 0 1px 2px rgba(0,0,0,.075);
  border-radius: 1rem;
  border-radius: 15px 15px;
  border-width: 2px;
  padding-left: 10px;
  padding-right: 10px;
  color: #e7eef8;
}

.char-limit{
  font-weight: bold;
}

.char-limit-exceed {
  font-weight: bold;
  color: #f35321;
}

.des-count{
  margin-top: 10px;
  text-align: right;
}

.des-count-exceed {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.exceed-alert {
  padding-left: 10px;
  color: #f35321;
}
.toolbar-rich-text-dark {
  background-color: #212529;
  border: 1px solid #343a40;
  border-radius: 1rem;
}

.toolbar-rich-text-light {
  background-color: white;
  border-radius: 1rem;
}
.tech-list {
    user-select: none;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-left: 20px;
    padding-bottom: 10px;
  }
  
  .tech-item {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 8px;
    cursor: grab;
  }

  .tech-submit {
    margin-top: 25px;
    margin-left: 415px;
  }

  .tech-delete-icon{
    margin-left: 7px;
    cursor: pointer;
  }

  .dropdown-content{
    width: 85% !important;
  }

  .tech-tag {
    padding: 8px 20px;
    cursor: default;
  }

  .tech-tag-edit {
    padding: 6px 13px;
  }

  .link-popup {
    height: auto !important;
  }

.tool-popup-dark {
  background-color: #212529 !important;
  box-shadow: none !important;
  border: 1px solid #5d5b5b !important;
}

.rdw-dropdownoption-active {
  background: #a7a7a8 !important;
}

.rdw-dropdownoption-highlighted {
  background: #a7a7a8 !important;
}

.multi-select-dark .panel-content{
  background-color: #2d3135 !important;
}

.multi-select-dark .search input{
  background-color: #2d3135 !important;
  color: #c5c5c9 !important;
}
.aed-card{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-left: 20px;
  padding-bottom: 10px;
  margin-right: 10px;
}
.aed-icon-box{
  width: 50px;
}
.sortable-image-modal-view {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 16px;
  max-width: 900px;
  margin: 2px 32px 48px;
  padding-bottom: 30px;
}
  
.multi-select-dark .dropdown-container {
  background-color: #252731 !important;
}

.multi-select-dark .select-item.selected {
  background-color: #a7a7a8 !important;
} 

.multi-select-dark .select-item:hover {
  background-color: #a7a7a8 !important;
} 
.aed-card-description{
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-left: 25px;
  padding-bottom: 10px;
  margin-right: 10px;
}
.aed-technology-invalid{
  color: #dc3545;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}
.aed-table{
  padding-left: 10px;
}
.modal-close-btn-dark {
  position: absolute;
  top: 10px;
  right: 5px;
  z-index: 1;
}
